<template>
    <div>
        <input
            :id="id"
            v-model="modelInput"
            :type="config?.type ?? 'text'"
            :placeholder="config?.placeholder ?? ''"
            class="form-control"
            :class="{
                [config.classCustom]: config.classCustom,
                'is-invalid': config?.error ?? false,
                disabled_cursor: disabled,
                'text-red': config?.hasWarning ?? false
            }"
            :name="config?.name ?? ''"
            :maxlength="config?.maxlength ?? null"
            :min="config?.min ?? ''"
            :max="config?.max ?? ''"
            :multiple="config?.multiple ?? false"
            :autofocus="config?.autofocus ?? false"
            :disabled="disabled"
            @input="handleInput"
            @change="handleChangeInput"
            @keypress="handleKeyPress"
        />
        <div v-if="config.error || config.hasWarning" class="invalid-feedback d-block">
            <span v-if="config.isRequired">{{
                $t('validateField.required', {
                    field: config.errorField
                })
            }}</span>
            <span v-if="config.isMaxLength">{{
                $t('validateField.max255', {
                    field: config.errorField
                })
            }}</span>
            <span v-if="config.isKatakana">{{
                $t('validateField.incorrectFormat', {
                    field: config.errorField
                })
            }}</span>
            <span v-if="config.isUrl"> {{ $t('validateField.incorrectURL') }}</span>
            <span v-if="config.isEmail">
                {{
                    $t('validateField.incorrectFormat', {
                        field: config.errorField
                    })
                }}</span
            >
            <span v-if="config.errorCustomMess">
                {{ config.errorCustomMess }}
            </span>
        </div>
    </div>
</template>

<script>
import { isNumber } from '@/utils/format';

export default {
    name: 'InputCommon',
    props: {
        id: {
            type: String,
            required: false,
            default: 'input'
        },
        model: {
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        config: {
            type: Object,
            required: true,
            default() {
                return {
                    classCustom: 'form-control',
                    name: '',
                    maxlength: null,
                    type: 'text',
                    placeholder: '',
                    min: '',
                    max: '',
                    isRequired: false,
                    isMaxLength: false,
                    isKatakana: false,
                    isUrl: false,
                    isEmail: false,
                    isNumber: false,
                    multiple: false,
                    autofocus: false,
                    hasWarning: true,
                    error: false,
                    errorCustomMess: ''
                };
            }
        }
    },
    data() {
        return {
            modelInput: ''
        };
    },
    watch: {
        '$props.model': {
            handler: function (newValue) {
                this.modelInput = newValue;
            },
            immediate: true
        },

        '$props.config': {
            handler: function (newConfig) {
                this.config = newConfig;
                this.$emit('update:config', this.config);
            },
            immediate: true,
            deep: true
        },

        modelInput: {
            handler: function (newModelInput) {
                let config = this.config;
                config.error = false;
                this.$emit('update:model', newModelInput);
                this.$emit('update:config', config);
            }
        }
    },
    methods: {
        handleInput() {
            this.$emit('update:model', this.modelInput);
            this.$emit('input', this.modelInput);
        },
        handleChangeInput() {
            this.$emit('update:model', this.modelInput);
            this.$emit('change', this.modelInput);
        },
        handleKeyPress(event) {
            let config = this.config;
            if (config.isNumber) {
                isNumber(event);
            }
            this.$emit('update:model', this.modelInput);
            this.$emit('keypress', this.modelInput);
        }
    }
};
</script>

<style lang="scss" scoped>
.text-red {
    color: #ff3d60;
}
</style>
