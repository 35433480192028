var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-control custom-radio"},[_c('div',[((_vm.config?.type ?? 'radio')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelRadio),expression:"modelRadio"}],class:{
                [_vm.config.classCustom]: _vm.config.classCustom,
                'custom-control-input': !_vm.config?.classCustom,
                disabled_cursor: _vm.disabled
            },attrs:{"id":`radio-${_vm.config?.id}`,"name":_vm.config?.name ?? '',"disabled":_vm.disabled,"type":"checkbox"},domProps:{"value":_vm.config?.value ?? 'on',"checked":Array.isArray(_vm.modelRadio)?_vm._i(_vm.modelRadio,_vm.config?.value ?? 'on')>-1:(_vm.modelRadio)},on:{"change":[function($event){var $$a=_vm.modelRadio,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.config?.value ?? 'on',$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.modelRadio=$$a.concat([$$v]))}else{$$i>-1&&(_vm.modelRadio=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.modelRadio=$$c}},_vm.handleChangeRadio]}}):((_vm.config?.type ?? 'radio')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelRadio),expression:"modelRadio"}],class:{
                [_vm.config.classCustom]: _vm.config.classCustom,
                'custom-control-input': !_vm.config?.classCustom,
                disabled_cursor: _vm.disabled
            },attrs:{"id":`radio-${_vm.config?.id}`,"name":_vm.config?.name ?? '',"disabled":_vm.disabled,"type":"radio"},domProps:{"value":_vm.config?.value ?? 'on',"checked":_vm._q(_vm.modelRadio,_vm.config?.value ?? 'on')},on:{"change":[function($event){_vm.modelRadio=_vm.config?.value ?? 'on'},_vm.handleChangeRadio]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelRadio),expression:"modelRadio"}],class:{
                [_vm.config.classCustom]: _vm.config.classCustom,
                'custom-control-input': !_vm.config?.classCustom,
                disabled_cursor: _vm.disabled
            },attrs:{"id":`radio-${_vm.config?.id}`,"name":_vm.config?.name ?? '',"disabled":_vm.disabled,"type":_vm.config?.type ?? 'radio'},domProps:{"value":_vm.config?.value ?? 'on',"value":(_vm.modelRadio)},on:{"change":_vm.handleChangeRadio,"input":function($event){if($event.target.composing)return;_vm.modelRadio=$event.target.value}}}),_c('label',{class:`${_vm.disabled ? 'disabled_cursor' : ''} custom-control-label`,attrs:{"for":`radio-${_vm.config.id}`}},[_vm._v(_vm._s(_vm.config.label))])]),(_vm.config.error)?_c('div',{staticClass:"invalid-feedback d-block"},[(_vm.config.isRequired)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.config.errorField })))]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }